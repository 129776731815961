<template>
  <div class="contact">
    <Header :isChange="isChange"></Header>
    <div id="bannerImg" class="banner">
      <!-- <img src="../assets/concatUs/header.png" alt="" /> -->
      <img src="https://oss-gongsi.zuoshang2019.com/gongsi/header7.png" alt="联系我们" />
    </div>
    <div class="contact_us">
      <div class="title">联系我们</div>
      <!-- <div class="subtitle">可通过以下三种方式和我们取得联系</div> -->
      <div class="btn_list">
        <!-- <div class="btn">
          <img src="../assets/concatUs/write.png" alt="填写需求" />
          <div class="tips">
            <div class="tips_top">填写需求</div>
            <div class="tips_bottom">点击进行需求填写</div>
          </div>
        </div> -->
        <a
          target="_blank"
          href="https://yzf.qq.com/xv/web/static/chat/index.html?sign=37ef9b97d27c02c120449fed1db4b86ad35cf1936152b2797ddedb7850f98be4b8d5d953e5965cead13bfe5093f550aff150a47a"
          class="btn"
        >
          <img src="../assets/concatUs/chat.png" alt="在线咨询" />
          <div class="tips">
            <div class="tips_top">在线咨询</div>
            <div class="tips_bottom">点击进入在线咨询</div>
          </div>
        </a>
        <a href="tel:18137835079" class="btn">
          <img src="../assets/concatUs/phone.png" alt="电话咨询" />
          <div class="tips">
            <div class="tips_top">电话咨询</div>
            <div class="tips_bottom">18137835079</div>
          </div>
        </a>
      </div>
    </div>
    <div id="allmap" class="map"></div>
    <Footer></Footer>
  </div>
</template>

<script>
//页面引入swiper
// import { swiper, swiperSlide } from "vue-awesome-swiper";
// import "swiper/swiper-bundle.css";
import $ from "jquery";
import Header from "../components/header";
import { getSaleList } from "../api/api";
import Footer from "../components/footer";

export default {
  name: "About",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      isChange: false,
    };
  },
  mounted() {
    var self = this;
    this.$nextTick(() => {
      setTimeout(() => {
        var stStart = $(window).scrollTop() + 74;
        var target = $("#bannerImg").height();
        if (stStart > target) {
          // $('#menu').addClass('active')
          self.isChange = true;
          // console.log(self.isChange);
        } else {
          // $('#menu').removeClass('active')
          self.isChange = false;
          // console.log(self.isChange);
        }
        console.log(target);
        $(window).scroll(function () {
          var st = $(window).scrollTop() + 74;

          if (st > target) {
            // $('#menu').addClass('active')
            self.isChange = true;
            // console.log(self.isChange);
          } else {
            // $('#menu').removeClass('active')
            self.isChange = false;
            // console.log(self.isChange);
          }
        });
      }, 100);
    });

    // new Swiper(".development-swiper", {
    //   loop: false,
    //   // 如果需要分页器
    //   pagination: ".swiper-pagination",
    //   slidesPerView: 1,
    //   // 如果需要前进后退按钮
    //   nextButton: ".swiper-button-next",
    //   prevButton: ".swiper-button-prev",
    //   // 如果需要滚动条
    //   // scrollbar: ".swiper-scrollbar",
    // });
    // 百度地图API功能
    // var map = new BMap.Map("allmap"); // 创建Map实例
    // map.centerAndZoom(new BMap.Point(113.726421,34.779004), 19); // 初始化地图,设置中心点坐标和地图级别
    // //添加地图类型控件
    // map.addControl(
    //   new BMap.MapTypeControl({
    //     mapTypes: [BMAP_NORMAL_MAP, BMAP_HYBRID_MAP],
    //   })
    // );
    // map.setCurrentCity("郑州"); // 设置地图显示的城市 此项是必须设置的
    // map.enableScrollWheelZoom(false); //开启鼠标滚轮缩放

    // var local = new BMap.LocalSearch(map, {
    //   renderOptions: { map: map },
    // });
    // local.search("郑州佐尚网络科技有限责任公司");
    // map.disableDragging();
    // 百度地图API功能
    var map = new BMap.Map("allmap");
    var point = new BMap.Point(113.810984,34.794341);
    map.centerAndZoom(point, 19);

    var marker = new BMap.Marker(point); // 创建标注
    map.addOverlay(marker); // 将标注添加到地图中
    var opts = {
      width: 200, // 信息窗口宽度
      height: 100, // 信息窗口高度
      title: "郑州佐尚网络科技有限责任公司", // 信息窗口标题
      message: "郑州佐尚网络科技有限责任公司",
    };
    var infoWindow = new BMap.InfoWindow("地址：河南省郑州市金水区正东龙润国际7楼721室", opts); // 创建信息窗口对象
    map.openInfoWindow(infoWindow, point); //开启信息窗口
    map.disableDragging();
    marker.addEventListener("click", function () {
      map.openInfoWindow(infoWindow, point); //开启信息窗口
    });
  },

  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.contact {
  .contact_us {
    width: 100%;
    padding: 30px 15px;
    .title {
      font-size: 20px;
      color: #333333;
      margin-bottom: 30px;
    }
    // .subtitle {
    //   font-size: 14px;
    //   color: #999999;
    //   margin-bottom: 60px;
    // }
    .btn_list {
      .btn {
        width: 100%;
        height: 70px;
        background: #ffffff;
        box-shadow: 0px 4px 10px 1px rgba(175, 175, 175, 0.3);
        border-radius: 5px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 10px;
        padding: 0 20px;
        text-decoration: none;
        cursor: pointer;
        // text-decoration: none;
        box-sizing: border-box;
        img {
          width: 40px;
          height: auto;
        }
        .tips {
          text-align: left;
          margin-left: 20px;
          .tips_top {
            font-size: 16px;
            color: #333333;
          }
          .tips_bottom {
            font-size: 12px;
            color: #999999;
          }
        }
      }
    }
  }
  .map {
    width: calc(100% - 30px);
    height: 300px;
    margin: 0 auto;
  }
}
</style>
