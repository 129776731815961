<template>
  <div class="about">
    <Header :isChange="isChange"></Header>
    <div id="bannerImg" class="banner">
      <!-- <img src="../assets/about/header.png" alt="" /> -->
      <img src="https://oss-gongsi.zuoshang2019.com/gongsi/header6.png" alt="关于我们" />
    </div>
    <div>
      <div class="company_profile">
        <h1 class="title">佐尚网络</h1>
        <h4 class="subtitle">Zuoshang Network</h4>
        <p class="text">
          郑州佐尚网络科技有限责任公司成立于2019年，秉承“诚信、专业、以客户为中心、持续创新”的精神，着力打造集“战略咨询、平台技术和专业服务“为一体的物联网科技公司。我们以物联网技术应用为基础，专注并服务于智慧城市的建设，以及智慧工厂的升级改造。
        </p>
        <p class="text">
          我们在城市信息化监管、智慧停车等领域有着丰富的技术服务经验，实现对传统管理模式的优化升级，助力城市的智能化改造。同时我们在工厂能源管理、车辆一卡通等领域拥有多种应用技术，真正帮助企业进行数字化转型。
        </p>
        <p class="text">
          我们以“提供最专业的技术服务，为客户创造最大价值”为使命，不断提升服务质量、提高技术水平。为城市以及企业的智能化发展提供便捷、优质的解决方案，我们坚持科技创新，立志成为中国互联网技术服务行业的佼佼者。
        </p>
      </div>
    </div>
    <div class="cooperative_partner">
      <div class="title">合作伙伴</div>
      <img src="../assets/homeImg/cooperative_partner.png" alt="" />
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
//页面引入swiper
// import { swiper, swiperSlide } from "vue-awesome-swiper";
// import "swiper/swiper-bundle.css";
import $ from "jquery";
import Header from "../components/header";
import { getSaleList } from "../api/api";
import Footer from "../components/footer";

export default {
  name: "About",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      isChange: false,
    };
  },
  mounted() {
    var self = this;
    this.$nextTick(() => {
      setTimeout(() => {
        var stStart = $(window).scrollTop() + 74;
        var target = $("#bannerImg").height();
        if (stStart > target) {
          // $('#menu').addClass('active')
          self.isChange = true;
          // console.log(self.isChange);
        } else {
          // $('#menu').removeClass('active')
          self.isChange = false;
          // console.log(self.isChange);
        }
        console.log(target);
        $(window).scroll(function () {
          var st = $(window).scrollTop() + 74;

          if (st > target) {
            // $('#menu').addClass('active')
            self.isChange = true;
            // console.log(self.isChange);
          } else {
            // $('#menu').removeClass('active')
            self.isChange = false;
            // console.log(self.isChange);
          }
        });
      }, 100);
    });

    // new Swiper(".development-swiper", {
    //   loop: false,
    //   // 如果需要分页器
    //   pagination: ".swiper-pagination",
    //   slidesPerView: 1,
    //   // 如果需要前进后退按钮
    //   nextButton: ".swiper-button-next",
    //   prevButton: ".swiper-button-prev",
    //   // 如果需要滚动条
    //   // scrollbar: ".swiper-scrollbar",
    // });
    // 百度地图API功能
    // var map = new BMap.Map("allmap"); // 创建Map实例
    // map.centerAndZoom(new BMap.Point(113.726877, 34.781133), 19); // 初始化地图,设置中心点坐标和地图级别
    // //添加地图类型控件
    // map.addControl(
    //   new BMap.MapTypeControl({
    //     mapTypes: [BMAP_NORMAL_MAP, BMAP_HYBRID_MAP],
    //   })
    // );
    // map.setCurrentCity("郑州"); // 设置地图显示的城市 此项是必须设置的
    // map.enableScrollWheelZoom(true); //开启鼠标滚轮缩放

    // var local = new BMap.LocalSearch(map, {
    //   renderOptions: { map: map },
    // });
    // local.search("老家河南酒业集团有限公司");
    // map.disableDragging();
  },

  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.about {
  .company_profile {
    padding: 0 15px;
    .title {
      display: inline-block;
      font-size: 20px;
      background-image: linear-gradient(to right, #36a6f7, #3563f6);
      -webkit-background-clip: text;
      color: transparent;
      margin: 0;
      margin-top: 30px;
    }
    .subtitle {
      font-size: 10px;
      color: #bbc6e4;
      margin: 0;
      // margin-top: 3px;
      margin-bottom: 14px;
    }
    .text {
      font-size: 14px;
      color: #555555;
      line-height: 22px;
      margin-bottom: 30px;
      text-align: justify;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .cooperative_partner {
    width: 100%;
    padding: 30px 0;
    img {
      width: 100%;
    }
    .title {
      font-size: 20px;
      color: #333333;
      margin-bottom: 30px;
    }
    // .subtitle {
    //   font-size: 14px;
    //   color: #999999;
    //   margin-bottom: 60px;
    // }
    img {
      width: 100%;
    }
  }
}
</style>
