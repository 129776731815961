<template>
  <div class="smart_city_content">
    <Header @link-jump="jump" :isChange="isChange"></Header>
    <div id="bannerImg" class="banner">
      <!-- <img src="../assets/smartCity/header.png" alt="" /> -->
      <!-- <img src="../assets/smartCity/header.png" alt="" /> -->
      <img src="https://oss-gongsi.zuoshang2019.com/gongsi/header9.png" alt="智慧城市" />
    </div>
    <div id="energy" class="smart_factory">
      <h2 class="title">智慧停车场解决方案</h2>
      <!-- <h4 class="subtitle">智慧城市涵盖了智慧停车场、智能监管、城市油烟监管等多个领域</h4> -->
      <div class="case">
        <div class="case_introduce">
          <div>
            <div class="detail">
              <!-- <h3 class="detail_title">智慧停车场</h3>
              <h4 class="detail_subtitle">Smart parking lot</h4>
              <h4 class="detail_list_title">智慧停车场解决方案</h4> -->
              <div class="case_img">
                <img src="../assets/smartCity/car.png" alt="智慧城市" />
              </div>
              <div class="list">
                <div class="item">
                  <div class="dot_big">
                    <div class="dot_small"></div>
                  </div>
                  <div>自动升降道闸，减少排队时长；</div>
                </div>
                <div class="item">
                  <div class="dot_big dot_big_1">
                    <div class="dot_small"></div>
                  </div>
                  <div>停车场系统自动扣费，减少人为干预，避免收费漏洞；</div>
                </div>
                <div class="item">
                  <div class="dot_big dot_big_2">
                    <div class="dot_small"></div>
                  </div>
                  <div>精准车牌识别，识别率可达到99.9%，识别精准高效；</div>
                </div>
                <div class="item">
                  <div class="dot_big dot_big_3">
                    <div class="dot_small"></div>
                  </div>
                  <div>
                    支持微信、支付宝、ETC等多种支付方式,满足各种用户需求,提高
                    电子支付效率。
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="btn">
        <button type="button">更多解决方案</button>
      </div> -->
    </div>
    <div id="sand" class="smart_factory smart_city">
      <h2 class="title">智能监管解决方案</h2>
      <!-- <h4 class="subtitle">智慧城市涵盖了智慧停车场、智能监管、城市油烟监管等多个领域</h4> -->
      <div class="case">
        <div class="case_introduce">
          <div>
            <div class="detail">
              <!-- <h3 class="detail_title">智能监管</h3>
              <h4 class="detail_subtitle">Intelligent supervision</h4>
              <h4 class="detail_list_title">智能监管解决方案</h4> -->
              <div class="case_img">
                <img src="../assets/smartCity/supervision.png" alt="" />
              </div>
              <div class="list">
                <div class="item">
                  <div class="dot_big">
                    <div class="dot_small"></div>
                  </div>
                  <div>通过ai识别技术，对不同场所进行监控管理；</div>
                </div>
                <div class="item">
                  <div class="dot_big dot_big_1">
                    <div class="dot_small"></div>
                  </div>
                  <div>通过在线下单、自助取货，简化客户采购流程，提高效率；</div>
                </div>
                <div class="item">
                  <div class="dot_big dot_big_2">
                    <div class="dot_small"></div>
                  </div>
                  <div>实时查看现场监控画面，以及查看历史监控画面；</div>
                </div>
                <div class="item">
                  <div class="dot_big dot_big_3">
                    <div class="dot_small"></div>
                  </div>
                  <div>
                    多场景应用，可对人流量、车辆进行监控，以及对潜在安全隐患 进行监控。
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="btn">
        <button type="button">更多解决方案</button>
      </div> -->
    </div>
    <div id="device" class="smart_factory">
      <h2 class="title">城市油烟监管解决方案</h2>
      <!-- <h4 class="subtitle">智慧城市涵盖了智慧停车场、智能监管、城市油烟监管等多个领域</h4> -->
      <div class="case">
        <div class="case_introduce">
          <div>
            <div class="detail">
              <!-- <h3 class="detail_title">城市油烟监管</h3>
              <h4 class="detail_subtitle">Urban lampblack supervision</h4>
              <h4 class="detail_list_title">城市油烟监管解决方案</h4> -->
              <div class="case_img">
                <img src="../assets/smartCity/city_supervision.png" alt="" />
              </div>
              <div class="list">
                <div class="item">
                  <div class="dot_big">
                    <div class="dot_small"></div>
                  </div>
                  <div>对城市商户油烟排放进行实时监测，提高城市环保质量；</div>
                </div>
                <div class="item">
                  <div class="dot_big dot_big_1">
                    <div class="dot_small"></div>
                  </div>
                  <div>设定油烟排放标准，对超标排放进行及时反馈；</div>
                </div>
                <div class="item">
                  <div class="dot_big dot_big_2">
                    <div class="dot_small"></div>
                  </div>
                  <div>对商户进行过定时巡检油烟排放量，排查潜在问题。</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="btn">
        <button type="button">更多解决方案</button>
      </div> -->
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
//页面引入swiper
// import { swiper, swiperSlide } from "vue-awesome-swiper";
// import "swiper/swiper-bundle.css";
import $ from "jquery";
import Footer from "../components/footer";
// import Swiper from "swiper";
// import "swiper/dist/css/swiper.min.css";
import Header from "../components/header";
// import { historyList } from "../utils/text1";
// import Footer from "../components/footer.vue";

export default {
  name: "SmartCity",
  components: {
    // swiper,
    // swiperSlide,
    Header,
    Footer,
  },
  data() {
    return {
      // workmanshipList:[3,2],
      isChange: false,
    };
  },
  mounted() {
    // this.swiper1.slideTo(2, 1000, false)
    var data = this.$route.query.data;
    console.log(data);
    if (data) {
      this.jump(data);
    }
    var self = this;
    this.$nextTick(() => {
      setTimeout(() => {
        var stStart = $(window).scrollTop() + 74;
        var target = $("#bannerImg").height();
        if (stStart > target) {
          // $('#menu').addClass('active')
          self.isChange = true;
          // console.log(self.isChange);
        } else {
          // $('#menu').removeClass('active')
          self.isChange = false;
          // console.log(self.isChange);
        }
        console.log(target);
        $(window).scroll(function () {
          var st = $(window).scrollTop() + 74;

          if (st > target) {
            // $('#menu').addClass('active')
            self.isChange = true;
            // console.log(self.isChange);
          } else {
            // $('#menu').removeClass('active')
            self.isChange = false;
            // console.log(self.isChange);
          }
        });
      }, 100);
    });
  },

  methods: {
    jump(data) {
      this.$nextTick(() => {
        setTimeout(() => {
          //获取指定的div盒
          let targetbox;
          console.log(data);
          if (data == 0) {
            targetbox = $("#energy");
          } else if (data == 1) {
            targetbox = $("#sand");
          } else if (data == 2) {
            targetbox = $("#device");
          } else {
            console.log("targetbox");
          }

          var valOfScroll = targetbox.offset().top - 100;
          //让滚轴从当前位置的scroll在600毫秒内偏移到位置为valOfScroll。
          $("html,body").animate({ scrollTop: valOfScroll }, 600);
          // targetbox.scrollIntoView({
          //   behavior: "smooth", // 平滑过渡
          //   // block: "start", // 上边框与视窗顶部平齐。默认值
          // });
        });
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.smart_city_content {
  .smart_factory {
    padding: 30px 0;
    background: #fff;
    .title {
      font-size: 20px;
      color: #333333;
    }
    // .subtitle {
    //   font-size: 14px;
    //   color: #999999;
    //   margin-top: 22px;
    //   margin-bottom: 60px;
    // }
    .case {
      width: 100%;
      padding: 0 15px;
      // display: flex;
      // justify-content: space-between;
      // align-items: flex-start;
      .case_introduce {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        .detail {
          text-align: left;
          .detail_title {
            text-align: center;
            font-size: 18px;
            color: #2f78ff;
            margin-top: 20px;
          }
          // .detail_subtitle {
          //   font-size: 20px;
          //   color: #0c243d;
          //   margin-top: 5px;
          //   margin-bottom: 15px;
          // }
          // .detail_list_title {
          //   font-size: 20px;
          //   color: #2f78ff;
          //   margin-bottom: 30px;
          // }
          .list {
            margin-top: 20px;
            .item {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              font-size: 14px;
              color: #333333;
              margin-bottom: 20px;
              div {
                text-align: justify;
              }
              .dot_big {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 10px;
                height: 10px;
                border-radius: 100%;
                background: #1fdb84;
                margin-right: 5px;
                flex-shrink: 0;
                &.dot_big_1 {
                  background: #2f78ff;
                }
                &.dot_big_2 {
                  background: #f78921;
                }
                &.dot_big_3 {
                  background: #f72181;
                }
                .dot_small {
                  width: 4px;
                  height: 4px;
                  border-radius: 100%;
                  background: #fff;
                }
              }
            }
          }
        }
      }
      .case_img {
        width: 100%;
        margin-top: 16px;
        img {
          width: 100%;
        }
      }
    }
    .btn {
      margin-top: 40px;
      button {
        width: 170px;
        height: 40px;
        background: #2f78ff;
        border-radius: 6px 6px 6px 6px;
        border: none;
        font-size: 14px;
        color: #fff;
        font-weight: bold;
      }
    }
  }
  .smart_city {
    background: #FAFBFC;
    .btn {
      button {
        background: #fff;
        border: 1px solid #2f78ff;
        color: #2f78ff;
      }
    }
  }
}
</style>
